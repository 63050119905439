
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.container {
  width: 480px;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  :global {
    .ant-alert-error {
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      .ant-alert-message {
        padding-left: 2px;
        color: #fc3f38;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }
    }
    .ant-alert-info {
      margin-bottom: 32px;
      padding: 12px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }
  }

  .title {
    padding-bottom: 8px;
    color: $primary-text-color;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 53px;
  }

  .register {
    font-size: 14px;
    width: 100%;
    color: #2d3036;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    a {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.06em;
      color: #101214;
    }
  }

  :global(.ant-input):focus::placeholder {
    color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
}

.loginBtn {
  width: 480px;
  height: 48px;
  border-radius: 60px;
}

.footer {
  display: flex;
  justify-content: space-between;

  a {
    font-size: 14px;
    // line-height: 24px;
    letter-spacing: 0.06em;
    color: #101214;
  }
}
.rememberFormItem {
  :global {
    .ant-form-item-control-input {
      min-height: 18px;
    }
  }
}
.passwordLabel {
  display: flex;
  justify-content: space-between;
  width: 480px;
}
.policy {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  font-weight: 400;
  padding-top: 30px;
  display: none;

  a {
    color: rgba(28, 28, 28, 0.6);
    font-size: 12px;
  }
}
@media (min-width: 350px) and (max-width: 554px) {
  .container {
    display: flex;
    width: 327px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 71px;
    .form {
      width: 100%;
    }

    .loginBtn {
      width: 100%;
    }
  }
  .policy {
    display: flex;
  }
}
@media (min-width: 555px) and (max-width: 1200px) {
  .policy {
    display: flex;
    position: absolute;
    bottom: 72px;
    text-align: center;
    width: 480px;
  }
}
.passwordIcon {
  padding-top: 4.5px;
  &:hover {
    svg path {
      fill: #8c8c8c;
    }
  }
}
